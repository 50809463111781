<template>
    <div>
        <Navbar :page="`Novo diagnóstico`" link="/diagnosticos" nameLink="Diagnósticos" />

        <div class="mx-4 my-4 md:mx-8 md:my-8">
                      
            <form action="#" method="POST">
                <div class="grid grid-cols-12 gap-4">
                    
                    <div class="flex flex-col col-span-12 md:col-span-5 gap-2">

                        <div>
                            <label for="diagnosticoBase" class="block text-sm font-medium">Diagnóstico base</label>
                            <select 
                                v-model="form.diagnosticoBase" 
                                name="diagnosticoBase" 
                                id="diagnosticoBase" 
                                class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                            >
                                <option :value="null" default>
                                   Selecione um diagnóstico
                                </option>
                                <option 
                                    v-for="(item, index) in diagnosticosBase" :key="index"
                                    :value="item._id">
                                    {{  item.tema }}
                                </option>
                            </select>
                        </div>

                        <div>
                            <label for="diagnosticoBase" class="block text-sm font-medium">
                                Tipo do diagnóstico
                            </label>
                            <select 
                                v-model="form.tipoDiagnostico" 
                                name="diagnosticoBase" 
                                id="diagnosticoBase" 
                                class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                            >
                                <option value="interno">Interno</option>
                                <option value="externo">Externo</option>
                            </select>
                        </div>

                        <div>
                            <label for="buscaParmsTipo" class="block text-sm font-medium">Tipo de destinatário</label>
                            <select v-model="buscaParms.tipo" name="buscaParmsTipo" id="buscaParmsTipo" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Pessoa na Empresa">Pessoa na Empresa</option>
                                <option value="Agente de Tratamento">Agente de Tratamento</option>
                            </select>
                        </div>

                        <div v-if="buscaParms.tipo !== 'Outro'" class="grid grid-cols-12 gap-2 mt-2">
                            <div class="col-span-12 md:col-span-9">
                                <label class="block text-sm font-medium">
                                    {{ buscaParms.tipo === 'Pessoa na Empresa' ? 'Setor' : 'Tipo Agente de tratamento' }}
                                </label>
                                <select v-if="buscaParms.tipo === 'Pessoa na Empresa'" v-model="buscaParms.setor" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option default :value="null">Todos os setores</option>
                                    <option v-for="s in setores" :key="s._id" :value="s._id">{{ s.nome }}</option>
                                </select>
                                <select v-if="buscaParms.tipo === 'Agente de Tratamento'" v-model="buscaParms.agente" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option default :value="null">Todos os tipos</option>
                                    <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label class="block text-sm font-medium">Buscar</label>
                                <button @click="startBusca()" type="button" class="inline-flex justify-center w-full py-2 px-2 mt-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    Buscar
                                </button>
                            </div>
                        </div>
                        <div v-if="buscaParms.tipo !== 'Outro'" class="mt-2">
                            <span class="font-semibold text-sm">Total: {{total}} <span @click="selecionarTodos()" class="text-green-700 underline cursor-pointer ml-1">Selecionar todos</span> <span class="text-blue-700 ml-1">Selecionados: {{selecionadosBusca.length}}</span></span>
                        </div>
                        <div v-if="busca.length && buscaParms.tipo !== 'Outro'" class="h-96 overflow-auto px-3 py-2 bg-gray-200 rounded-lg mt-2">
                            <label v-for="item in busca" :key="item._id" class="block text-sm font-medium my-1">
                                <input type="checkbox" v-model="item.check" @change="changeBusca(item)" class="rounded-sm" :id="item._id" v-if="!item.adicionado">
                                <input type="checkbox" v-model="sempreMarcado" class="rounded-sm text-gray-400" :id="item._id" disabled v-else> 
                                <span :class="`ml-2 ${item.adicionado ? 'text-gray-500': ''}`">
                                    {{ item.nome }}<span v-if="item.email"> - {{ item.email }} ({{ item.setor ? item.setor.nome : 'Sem setor' }} / {{ item.funcao ? item.funcao.nome : 'Sem Função' }})</span>
                                </span>
                            </label>
                        </div>
                        <button v-if="total > 0 && buscaParms.tipo !== 'Outro'" @click="addConvidados()" type="button" class="inline-flex justify-center py-1 px-2 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                            Adicionar destinatários
                        </button>
                    </div>
        
                    <div class="col-span-12 md:col-span-7">
                        <div class="col-span-12 md:col-span-7 mb-1">
                            <label for="conteudoMsg" class="block text-xl font-medium">
                                Personalize a comunicação via e-mail
                            </label>
                            <textarea 
                                type="text" 
                                rows="5" 
                                v-model="form.conteudoMsg" 
                                name="conteudoMsg" 
                                id="conteudoMsg" 
                                class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                            >
                            </textarea>
                        </div>
                        <h3 class="text-lg font-medium">Lista de destinatários</h3>
                        <span class="font-semibold text-green-600 text-sm">
                            Total: {{totalConvidados}} 
                        </span>
                        <span 
                            @click="removerConvidados" 
                            v-if="selecionadosConvidados.length" 
                            class="text-sm font-semibold text-red-700 underline cursor-pointer ml-1">
                            Remover selecionados
                        </span> 
                        <span v-if="selecionadosConvidados.length" class="text-sm font-semibold text-blue-700 ml-1">
                            Selecionados: {{selecionadosConvidados.length}}
                        </span>
                        <div v-if="convidados.length" class="h-120 overflow-auto mt-2">
                            <div class="py-2 align-middle inline-block min-w-full">
                                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-200">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                                                    <svg @click="selecionarTodosConvidados()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                </th>
                                                <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Destinatário
                                                </th>
                                                <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    E-mail
                                                </th>
                                                <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Status
                                                </th>
                                                <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Confirmação de recebimento
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-for="(item, index) in convidados" :key="index">
                                                <td v-if="!$store.state.user.cliente" class="px-1 py-2">
                                                    <div class="flex items-center">
                                                        <input :checked="selecionadosConvidados.map(el => el.id).indexOf(item.id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                                                    </div>
                                                </td>
                                                <td class="px-3 py-2">
                                                    <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{item.nome ? item.nome : ''}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-3 py-2">
                                                    <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{item.email ? item.email : ''}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-3 py-2">
                                                    <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{ item.status }}{{ item.data_envio ? ` - ${formatDate(item.data_envio)}` : '' }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-3 py-2">
                                                    <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{  formatDate(item.recebimento_confirmado) || '-' }}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <h4 v-else class="text-base">Nenhum destinatário</h4>
                    </div>
                </div>
                <div class="px-5 pb-4">
                </div>
            </form>
            

            <div class="grid grid-cols-2 mt-14">
                <div class="px-5 py-3 text-left sm:px-6">
                    <button @click="$router.push({ path: route})" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="px-5 py-3 text-right sm:px-6">
                    <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
        <modal name="documentoMaterial" :width="800" :height="'auto'" :adaptive="true" :reset="true">
        <div class="px-3 py-2 overflow-y-auto h-full">
            <h2 class="text-xl font-semibold mb-1"> Adicionar material de apoio </h2>
            <vue-dropzone ref="docDocumento" id="docDocumento" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateDocumento"></vue-dropzone>
        </div>
        </modal>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');
export default {
    components: {
        vueDropzone,
    },
    data() {
        return {
            route: '/diagnosticos',
            idx: 0,
            sempreMarcado: true,
            outro: { nome: "", email: "" },
            diagnosticosBase: [],
            form: {
                diagnosticoBase: null,
                empresa: '',
                destinatariosAgente: [],
                destinatariosPessoas: [],
                conteudoMsg: '',
                quantidadeDestinatarios: 0,
                vinculado_pai: false,
                tipoDiagnostico: 'interno',
            },
            listaAgentes: [],
            listaPessoas: [],
            convidados: [],
            selecionadosConvidados: [],
            totalConvidados: 0,
            setores: [],
            agentesTratamento: [],
            busca: [],
            selecionadosBusca: [],
            total: 0,
            buscaParms: {
                tipo: "Pessoa na Empresa",
                setor: null,
                agente: null,
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste o arquivo aqui',
                dictRemoveFile: 'Remover'
            }),
        }
    },
    methods: {
        formatDate(data) {
            if(!data) return '';
            return `${moment(data).format("DD/MM/YYYY")}`;
        },
        async save() {
            
            const diagnosticoBaseSelecionado = this.diagnosticosBase.find((item)=> item._id === this.form.diagnosticoBase)
            if(diagnosticoBaseSelecionado){
                this.form.vinculado_pai = diagnosticoBaseSelecionado.incluirPAI
            }else{
                return this.$vToastify.warning('Selecione diagnóstico base.');
            }
           
            this.form.quantidadeDestinatarios = this.convidados.length
            
            this.form.destinatariosPessoas = []
            this.form.destinatariosAgente = []
            
            this.convidados.forEach(convidado => {
                const { id } = convidado;

                console.log(convidado)

                const agenteEncontrado = this.listaAgentes.find(agente => agente._id === id);
                const pessoaEncontrada = this.listaPessoas.find(pessoa => pessoa._id === id);

                if (agenteEncontrado) {
                    this.form.destinatariosAgente.push({
                        destinatario: id,
                        status: convidado.status,
                        data_envio: convidado.data_envio,
                        recebimento_confirmado: null,
                    });
                }else if(pessoaEncontrada){
                    this.form.destinatariosPessoas.push({
                        destinatario: id,
                        setor: pessoaEncontrada?.setor?._id,
                        status: convidado.status,
                        data_envio: convidado.data_envio,
                        recebimento_confirmado: null,
                    });
                }
            });

            const method = this.form._id ? 'put' : 'post';
            await this.$http[method](`/v1/diagnosticoControle`, this.form)
            .then((res)=>{
                this.$vToastify.success(res.data?.msg);
                this.$router.push({path: this.route});
            })
            .catch((e)=>this.$vToastify.error(e.response?.data?.err))
        },
        async update(file, response) {
            this.form.arte = response.file;
        },
        async updateDocumento(file, response) {
            this.$refs.docDocumento.removeFile(file);
            this.form.documentos.push(response.file);
            this.$modal.hide('documentoMaterial');
        },
        async updateDocumentoEvidencias(file, response, tipo) {
            this.$refs['doc' + tipo].removeFile(file);
            response.file.tipoEvidencia = tipo;
            this.form.evidencias.push(response.file);
        },
        async removerConvidados(){

            this.convidados = this.convidados.filter(convidado => 
                !this.selecionadosConvidados.some(selecionadoConvidado => convidado.id === selecionadoConvidado.id)
            );

            this.totalConvidados = this.convidados.length;
            
            this.selecionadosConvidados = [];
            this.marcarAdicionados();

        },
        selecionadosControl(item, e){
            if (this.selecionadosConvidados.map(el => el.id).indexOf(item.id) > -1 && !e.target.checked) {
                this.selecionadosConvidados.splice(this.selecionadosConvidados.map(el => el.id).indexOf(item.id), 1);
            } else {
                this.selecionadosConvidados.push(item);
            }
        },
        selecionarTodosConvidados(){
            for(let i = 0; i < this.convidados.length; i++){
                const item = this.convidados[i];
                if(this.selecionadosConvidados.map(el => el.id).indexOf(item.id) ==! -1) this.selecionadosConvidados.splice(this.selecionadosConvidados.map(el => el.id).indexOf(item.id), 1);
                else this.selecionadosConvidados.push(item);
            }
        },
        async addConvidados(){
           
            this.selecionadosBusca.forEach((selecionado) => {
                if (!this.convidados.some(convidado => convidado.id === selecionado.id)) {
                    this.convidados.push(selecionado);
                }
            });
            this.totalConvidados = this.convidados.length;

            this.selecionadosBusca = [];
            
            this.desmarcarOpcoes();
            this.marcarAdicionados();

        },
        changeBusca(item){
            if(item.check) {
                this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
            }else {
                const index = this.selecionadosBusca.map(el => el.id).indexOf(item._id);
                if(index !== -1){
                    this.selecionadosBusca.splice(index, 1);
                }
            }
        },
        selecionarTodos() {
            const acao = this.selecionadosBusca.length > 0 ? 'removerTudo' : 'adicionarTudo';

            if (acao === 'removerTudo') {
                this.desmarcarOpcoes();
                this.selecionadosBusca = [];
            }

            if (acao === 'adicionarTudo') {
                for(let i = 0; i < this.busca.length; i++){
                    const item = this.busca[i];
                    item.check = true;
                    this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
                }
            }
        },
        desmarcarOpcoes(){
            this.busca.forEach((item)=>{
                item.check =false;
            });
        },
        marcarAdicionados() {
            for (let i = 0; i < this.busca.length; i++) {
                const item = this.busca[i];
                const indiceAdicionado = this.convidados.findIndex((convidado) => convidado.id === item._id);

                if (indiceAdicionado !== -1) this.busca[i].adicionado = true;
                else this.busca[i].adicionado = false;
            }
        },
        async pegarPossiveisDestinatarios(){
            const reqPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true });
            this.listaPessoas = reqPessoas.data.data;

            const reqFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true });
            this.listaAgentes = reqFornecedores.data.data;
        },
        async startBusca(){
            if(this.selecionadosBusca.length > 0) {
                return this.$vToastify.error("Adicione os selecionados aos convidados, antes de realizar uma nova busca!");
            }

            if(this.buscaParms.tipo === 'Pessoa na Empresa') {
                const reqbusca = await this.$http.post(`/v1/pessoas/list`, { all: true, setor: this.buscaParms.setor });
                this.busca = reqbusca.data.data;
                this.total = reqbusca.data.total;
            }
            if(this.buscaParms.tipo === 'Agente de Tratamento') {
                const reqbusca = await this.$http.post(`/v1/fornecedores/list`, { all: true, agente: this.buscaParms.agente });
                this.busca = reqbusca.data.data;
                this.total = reqbusca.data.total;
            }
            this.marcarAdicionados();
        },

    },
    async beforeMount() {

        const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
        this.form.empresa = this.$store.state.empresa._id

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listAgentesTratamento = await this.$http.post(`/v1/agentes/list`, { all: true});
        this.agentesTratamento = listAgentesTratamento.data.data;
        
        this.pegarPossiveisDestinatarios();

        await this.$http.post(`/v1/diagnosticosBase/list`)
        .then((resp)=> this.diagnosticosBase = resp.data.itens)

        this.startBusca();

        if (id) {
            await this.$http.get(`/v1/diagnosticoControle/${id}`)
            .then((resp)=> {
                Object.assign(this.form, resp.data)
                console.log(this.form)

                const agentes = (this.form.destinatariosAgente || []).map(item => ({
                    id: item.destinatario?._id,
                    nome: item.destinatario?.nome,
                    email: item.destinatario?.email || '',
                    status: item.status || '',
                    recebimento_confirmado: item.recebimento_confirmado || '',
                    data_envio: item.data_envio
                }));

                const pessoas = (this.form.destinatariosPessoas || []).map(item => ({
                    id: item.destinatario?._id,
                    nome: item.destinatario?.nome,
                    email: item.destinatario?.email || '',
                    status: item.status || '',
                    recebimento_confirmado: item.recebimento_confirmado || '',
                    data_envio: item.data_envio
                }));
                    
                this.convidados = [...agentes, ...pessoas];
            })
            .catch(()=>{
                this.$vToastify.error('Erro ao obter diagnóstico')
                this.$router.push({path: this.route});
                
            });
        }
    },
}
</script>
